html, body { height: 100%;
  background-color: #F3F5F7;
}
@font-face {
  font-family: ProximaNova;
  src: url(assets/Font/ProximaNova.ttf);
}

.v-middle{
  vertical-align: middle;
}


